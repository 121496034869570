/*
 * @Description: 并发请求逻辑
 * @Author: LiangYiNing
 * @Date: 2022-02-18 14:01:59
 * @LastEditTime: 2022-02-21 15:52:42
 * @LastEditors: LiangYiNing
 */
export default {
  data() {
    return {
      concurrentRequestOption: {
        // 请求接口API
        requestsAPI: null,
        // 最大并发请求量
        maxReq: 5,
        // 最大单个http的数据量
        maxDataSize: 20
      }
    };
  },
  computed: {},
  methods: {
    // 将数据分组并分批请求
    async concurrentRequest(dataList) {
      let groupData = this.dataGrouping(dataList);
      let resList = await this.concurrentRequestMaxReq(groupData);
      return resList;
    },
    // 数据分组
    dataGrouping(dataList) {
      if (!Array.isArray(dataList) || !dataList.length) return [];
      dataList = [...dataList];
      let groups = [];
      let { maxDataSize } = this.concurrentRequestOption;
      while (dataList.length) groups.push(dataList.splice(0, maxDataSize));
      return groups;
    },
    // 分批请求
    async concurrentRequestMaxReq(groupData) {
      if (!Array.isArray(groupData) || !groupData.length) return [];
      let resList = [];
      while (groupData.length) {
        let { maxReq } = this.concurrentRequestOption;
        let handleResData = groupData.splice(0, maxReq);
        try {
          let handleRes = await Promise.all(
            handleResData.map(item => {
              let resparam = this.concurrentRequestBeforeReq
                ? this.concurrentRequestBeforeReq(item)
                : item;
              return this.concurrentRequestOption.requestsAPI(resparam);
            })
          );

          handleRes.map(item => {
            this.concurrentRequestAfterReq(item);
            resList.push(item);
          });
        } catch (error) {
          console.log(error);
        }
      }
      return resList;
    },
    /**
     * 请求之前的回调
     * 1.必须返回值， 返回的值回被直接作为入参
     * 2.可以在这里操作入参修改 需在组件内覆盖此函数
     * @param handleParamData 默认入参(逻辑分组后的结果)
     * @return 返回的结果作为接口入参
     */
    concurrentRequestBeforeReq(handleParamData) {
      return handleParamData;
    },
    /**
     * 单批请求成功后的回调
     * @param res 单次请求的结果响应体
     */
    concurrentRequestAfterReq() {}
  }
};
