var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-dialog',{directives:[{name:"dialogDrag",rawName:"v-dialogDrag"},{name:"loading",rawName:"v-loading",value:(_vm.editDialogLoading),expression:"editDialogLoading"}],attrs:{"title":"修改卡信息","visible":_vm.editVisible,"width":"500px","before-close":_vm.closeDialog,"close-on-click-modal":false,"element-editDialogLoading-background":_vm.loadingBackground,"custom-class":"edit-dialog"},on:{"update:visible":function($event){_vm.editVisible=$event},"open":_vm.openDialog}},[_c('el-form',{ref:"editSimForm",attrs:{"label-width":"100px","model":_vm.editSimForm,"rules":_vm.editSimFormRules}},[_c('el-form-item',{attrs:{"label":"卡套餐","prop":"simPackageId"}},[_c('e6-vr-select',{attrs:{"data":_vm.simPackageEnum,"placeholder":"SIM卡套餐","title":"SIM卡套餐","props":{
          id: 'simPackageId',
          label: 'simPackageName'
        },"clearable":""},model:{value:(_vm.editSimForm.simPackageId),callback:function ($$v) {_vm.$set(_vm.editSimForm, "simPackageId", $$v)},expression:"editSimForm.simPackageId"}})],1),_vm._v(" "),_c('el-form-item',{attrs:{"label":"卡属性","prop":"simPropId"}},[_c('e6-vr-select',{attrs:{"data":_vm.cardAttributeEnum,"placeholder":"卡属性","title":"卡属性","props":{
          id: 'simPropId',
          label: 'simPropName'
        },"clearable":""},model:{value:(_vm.editSimForm.simPropId),callback:function ($$v) {_vm.$set(_vm.editSimForm, "simPropId", $$v)},expression:"editSimForm.simPropId"}})],1),_vm._v(" "),_c('el-form-item',{attrs:{"label":"卡类型","prop":"simTypeId"}},[_c('e6-vr-select',{attrs:{"data":_vm.cardTypeEnum,"placeholder":"卡类型","title":"卡类型","props":{
          id: 'simTypeId',
          label: 'simTypeName'
        },"clearable":""},model:{value:(_vm.editSimForm.simTypeId),callback:function ($$v) {_vm.$set(_vm.editSimForm, "simTypeId", $$v)},expression:"editSimForm.simTypeId"}})],1),_vm._v(" "),_c('el-form-item',{attrs:{"label":"是否需要报停","prop":"needStop"}},[_c('e6-vr-select',{attrs:{"data":_vm.haveToStopEnum,"placeholder":"是否需要报停","title":"是否需要报停","props":{
          id: 'codeValue',
          label: 'codeName'
        },"clearable":""},model:{value:(_vm.editSimForm.needStop),callback:function ($$v) {_vm.$set(_vm.editSimForm, "needStop", $$v)},expression:"editSimForm.needStop"}})],1)],1),_vm._v(" "),_c('div',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{staticClass:"cancel",on:{"click":_vm.closeDialog}},[_vm._v("取消")]),_vm._v(" "),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.handleSubmit}},[_vm._v("确定")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }