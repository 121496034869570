<!--
 * @Description: sim卡列表
 * @Author: ChenXueLin
 * @Date: 2021-09-09 09:49:29
 * @LastEditTime: 2022-07-15 15:59:16
 * @LastEditors: ChenXueLin
-->
<template>
  <div class="content-view-wrapper pd-10">
    <div
      class="content-view section-wrapper"
      ref="sectionWrapper"
      v-loading="loading"
      :element-loading-background="loadingBackground"
    >
      <!-- 搜索头部 start -->
      <table-title @resize="handleTableTitleResize">
        <template v-slot:searchForm>
          <el-form class="search-list" ref="searchForm" :model="searchForm">
            <el-form-item class="search-item--1" prop="realNo">
              <el-input
                v-model="searchForm.realNo"
                placeholder="SIM卡号"
                title="SIM卡号"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="commids">
              <el-input
                v-model="searchForm.commids"
                placeholder="中心识别码"
                title="中心识别码"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="haveName">
              <el-input
                v-model="searchForm.haveName"
                placeholder="持有者"
                title="持有者"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="ownerType">
              <e6-vr-select
                v-model="searchForm.ownerType"
                :data="ownerTypeEnum"
                placeholder="持有者类型"
                title="持有者类型"
                :props="{
                  id: 'codeValue',
                  label: 'codeName'
                }"
                clearable
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="thirdClassId">
              <e6-vr-select
                v-model="searchForm.thirdClassId"
                :data="equipThirdEnum"
                placeholder="设备三级分类"
                title="设备三级分类"
                :props="{
                  id: 'equipThirdId',
                  label: 'equipThirdName'
                }"
                clearable
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="cardAttribute">
              <e6-vr-select
                v-model="searchForm.cardAttribute"
                :data="cardAttributeEnum"
                placeholder="卡属性"
                title="卡属性"
                :props="{
                  id: 'simPropId',
                  label: 'simPropName'
                }"
                clearable
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="cardType">
              <e6-vr-select
                v-model="searchForm.cardType"
                :data="cardTypeEnum"
                placeholder="卡类型"
                title="卡类型"
                :props="{
                  id: 'simTypeId',
                  label: 'simTypeName'
                }"
                clearable
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="status">
              <e6-vr-select
                v-model="searchForm.status"
                :data="cardStatusEnum"
                placeholder="卡状态"
                :props="{
                  id: 'codeValue',
                  label: 'codeName'
                }"
                title="卡状态"
                clearable
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="equipCode">
              <el-input
                v-model="searchForm.equipCode"
                placeholder="已绑定设备编号"
                title="已绑定设备编号"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="supplierName">
              <el-input
                v-model="searchForm.supplierName"
                placeholder="供应商"
                title="供应商"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="isPrivate">
              <e6-vr-select
                v-model="searchForm.isPrivate"
                :data="isPrivateEnum"
                placeholder="是否自有卡"
                title="是否自有卡"
                :props="{
                  id: 'codeValue',
                  label: 'codeName'
                }"
                clearable
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="haveToStop">
              <e6-vr-select
                v-model="searchForm.haveToStop"
                :data="haveToStopEnum"
                placeholder="是否需要报停"
                title="是否需要报停"
                :props="{
                  id: 'codeValue',
                  label: 'codeName'
                }"
                clearable
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="createdBy">
              <el-input
                v-model="searchForm.createdBy"
                placeholder="创建人"
                title="创建人"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--2" prop="banCardTime">
              <e6-date-picker
                popper-class="no-atTheMoment"
                label="开卡日期"
                ref="banCardTime"
                v-model="searchForm.banCardTime"
                title="开卡日期"
                :picker-options="pickerOptions('searchForm.banCardTime')"
                :default-time="['00:00:00', '23:59:59']"
                start-placeholder="开卡日期（始）"
                end-placeholder="开卡日期（止）"
              ></e6-date-picker>
            </el-form-item>
            <el-form-item class="search-item--2" prop="stopTime">
              <e6-date-picker
                popper-class="no-atTheMoment"
                label="报停日期"
                ref="stopTime"
                v-model="searchForm.stopTime"
                title="报停日期"
                :picker-options="pickerOptions('searchForm.stopTime')"
                :default-time="['00:00:00', '23:59:59']"
                start-placeholder="报停日期（始）"
                end-placeholder="报停日期（止）"
              ></e6-date-picker>
            </el-form-item>
            <el-form-item class="search-item--2" prop="createTime">
              <e6-date-picker
                popper-class="no-atTheMoment"
                label="创建时间"
                ref="createTime"
                v-model="searchForm.createTime"
                title="创建时间"
                :picker-options="pickerOptions('searchForm.createTime')"
                :default-time="['00:00:00', '23:59:59']"
                start-placeholder="创建时间（始）"
                end-placeholder="创建时间（止）"
              ></e6-date-picker>
            </el-form-item>
            <el-form-item class="search-item--buttons">
              <el-button type="primary" @click="handleSearch">查询</el-button>
              <el-button class="reset" @click="handleReset">重置</el-button>
            </el-form-item>
          </el-form>
        </template>

        <template v-slot:operateAction>
          <i title="导入非自有卡" @click="simImport(2)">导入自有卡</i>
          <i title="导入非自有卡" @click="simImport(0)">导入非自有卡</i>
          <i title="导入操作" @click="simImport(1)">
            导入操作
          </i>
          <i title="批量修改卡信息" @click="handleEdit">批量修改卡信息</i>
          <i title="开通" @click="changeSim(2)">开通</i>
          <i title="报停" @click="changeSim(1)">报停</i>
          <i
            class="e6-icon-export_line"
            title="导出"
            @click="exportSimData"
          ></i>
        </template>
      </table-title>
      <!-- 搜索头部 end -->
      <!-- 表格 start -->
      <section class="table-wrapper">
        <el-table
          border
          ref="tableList"
          :height="resizeViewHeight - 10 + 'px'"
          :data="tableData"
          highlight-current-row
          class="elTable"
          @selection-change="handleSelectionChange"
          @select-all="handleSelectAll"
        >
          <el-table-column
            type="selection"
            width="40"
            :selectable="checkSelectable"
          >
          </el-table-column>
          <!-- 序号 start -->
          <el-table-column
            label="序号"
            show-overflow-tooltip
            :min-width="100"
            fixed="left"
            header-align="center"
            align="center"
          >
            <template slot-scope="scope">
              <span>
                {{
                  scope.$index +
                    1 +
                    (searchForm.pageIndex - 1) * searchForm.pageSize
                }}
              </span>
            </template>
          </el-table-column>
          <!-- 序号 end -->
          <el-table-column
            show-overflow-tooltip
            v-for="(column, index) in columnData"
            :key="index"
            :prop="column.fieldName"
            :label="column.fieldLabel"
            :min-width="column.width"
            :fixed="column.fixed"
            :align="column.align"
            header-align="center"
          ></el-table-column>
          <el-table-column fixed="right" align="center" label="操作" width="70">
            <template slot-scope="scope">
              <e6-td-operate
                :data="getOperateList()"
                @command="handleOperate($event, scope.row)"
              ></e6-td-operate>
            </template>
          </el-table-column>
        </el-table>
      </section>
      <!-- 表格 end -->
      <!-- 分页 start -->
      <section class="pagination-wrapper fixed-section">
        <el-pagination
          :page-size.sync="searchForm.pageSize"
          :current-page.sync="searchForm.pageIndex"
          :page-sizes="pageSizes"
          :layout="layout"
          :total="total"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </section>
      <!-- 分页 end -->
    </div>
    <!-- 编辑卡信息 -->
    <edit-dialog
      :editVisible="editVisible"
      @handleClose="handleClose"
      @afterSaveSuccess="queryList()"
      :selColumnId="selColumnId"
      :simPackageEnum="simPackageEnum"
      :cardAttributeEnum="cardAttributeEnum"
      :cardTypeEnum="cardTypeEnum"
      :haveToStopEnum="haveToStopEnum"
      type="1"
    ></edit-dialog>
  </div>
</template>

<script>
import base from "@/mixins/base";
import listPage from "@/mixins/list-page";
import listPageReszie from "@/mixins/list-page-resize";
import EditDialog from "./editDialog.vue";
import { exportExcelByUrl, exportMessageHandle } from "@/utils/download";
import concurrentRequest from "@/mixins/concurrentRequest";

import { e3Api } from "@/config/api";
const { E3_BL_STE } = e3Api;
let nowTime = new Date().getTime();
import {
  getSimList,
  simExport,
  updateSim,
  findDownList,
  getPackageEnum,
  getPropEnum,
  getTypeEnum,
  getEquipThirdEnum
} from "@/api";
import { printError } from "@/utils/util";
export default {
  name: "simManage",
  mixins: [listPage, listPageReszie, base, concurrentRequest],
  data() {
    return {
      concurrentRequestOption: {
        requestsAPI: updateSim
      },
      handleType: null,
      timePickerResetList: ["createTime", "banCardTime", "stopTime"], // base mixin的配置项 需要重置的timePicker refname
      cardStatusEnum: [], //卡状态枚举
      isPrivateEnum: [], //是否自有卡枚举
      haveToStopEnum: [], //是否需要报停枚举
      ownerTypeEnum: [], // 持有者类型枚举
      simPackageEnum: [], // 卡套餐枚举
      cardAttributeEnum: [], // 卡属性枚举
      cardTypeEnum: [], // 卡类型枚举
      equipThirdEnum: [], // 设备三级分类枚举
      searchForm: {
        realNo: "", //sim卡号
        commids: "", //中心识别码
        haveName: "", //持有者
        ownerType: "", //持有者类型
        thirdClassId: "", //sim卡套餐
        cardAttribute: "", //卡属性
        cardType: "", //卡类型
        status: "", //卡状态
        equipCode: "", //已绑定设备编号
        supplierName: "", //供应商
        isPrivate: "", //是否自有卡
        haveToStop: "", //是否需要报停
        createdBy: "", //创建人
        banCardTime: [], //开卡日期
        banCardStartTime: "", //开卡开始日期
        banCardEndTime: "", //开卡结束日期
        stopTime: [], //报停日期
        stopStartTime: "", //报停开始日期
        stopEndTime: "", //报停结束日期
        createTime: [nowTime - 2592000000], //创建日期
        createStartTime: nowTime - 2592000000, //创建开始日期 默认一个月
        createEndTime: "", //创建结束日期
        pageIndex: 1,
        pageSize: 20,
        sortIndex: "createdTime", // 默认创建时间排序
        sortDir: "descending" // 默认降序
      },
      total: 0,
      columnData: [
        {
          fieldName: "serialNo",
          display: true,
          fieldLabel: "序列号",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "realNo",
          display: true,
          fieldLabel: "SIM卡号",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "commids",
          display: true,
          fieldLabel: "中心识别码",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "haveName",
          display: true,
          fieldLabel: "持有者",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "haveType",
          display: true,
          fieldLabel: "持有者类型",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "thirdClassName",
          display: true,
          fieldLabel: "设备三级分类",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "monthlyFlow",
          display: true,
          fieldLabel: "套餐每月流量（M）",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center",
          mode: "color",
          color: "#79a3f2"
        },
        {
          fieldName: "monthlyFee",
          display: true,
          fieldLabel: "月租费（元）",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "flowCellno",
          display: true,
          fieldLabel: "流量池编号",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "simPropName",
          display: true,
          fieldLabel: "卡属性",
          width: 80,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "simTypeName",
          display: true,
          fieldLabel: "卡类型",
          width: 80,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "status",
          display: true,
          fieldLabel: "卡状态",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "equipName",
          display: true,
          fieldLabel: "已绑定设备",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "equipCode",
          display: true,
          fieldLabel: "已绑定设备编号",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "supplierName",
          display: true,
          fieldLabel: "供应商",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "isPrivate",
          display: true,
          fieldLabel: "是否自有卡",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "haveToStop",
          display: true,
          fieldLabel: "是否需要报停",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "beginDate",
          display: true,
          fieldLabel: "开通日期",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "stopDate",
          display: true,
          fieldLabel: "报停日期",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "stopReason",
          display: true,
          fieldLabel: "报停原因",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "remark",
          display: true,
          fieldLabel: "备注",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "employeeName",
          display: true,
          fieldLabel: "创建人",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "createdTime",
          display: true,
          fieldLabel: "创建时间",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        }
      ], //表头
      tableData: [], // 表格数据
      selColumnId: [], //选中的数据
      editVisible: false
    };
  },
  watch: {
    // 创建开始时间
    "searchForm.createTime": {
      immediate: true,
      handler(val) {
        if (val[0] && val[1] && val[0] > val[1]) {
          this.searchForm.createTime = this.searchForm.createTime.reverse();
        }
        this.searchForm.createStartTime = val[0] || "";
        this.searchForm.createEndTime = val[1] || "";
      }
    },
    // 报停时间
    "searchForm.stopTime": {
      immediate: true,
      handler(val) {
        if (val[0] && val[1] && val[0] > val[1]) {
          this.searchForm.stopTime = this.searchForm.stopTime.reverse();
        }
        this.searchForm.stopStartTime = val[0] || "";
        this.searchForm.stopEndTime = val[1] || "";
      }
    },
    // 开卡时间时间
    "searchForm.banCardTime": {
      immediate: true,
      handler(val) {
        if (val[0] && val[1] && val[0] > val[1]) {
          this.searchForm.banCardTime = this.searchForm.banCardTime.reverse();
        }
        this.searchForm.banCardStartTime = val[0] || "";
        this.searchForm.banCardEndTime = val[1] || "";
      }
    }
  },
  components: { EditDialog },
  created() {
    this.queryList();
    this.initData();
  },
  mounted() {
    this.setTableScrollDOM();
  },
  activated() {
    this.setScrollRecord();
  },
  methods: {
    //获取sim卡列表
    async queryList() {
      try {
        this.loading = true;
        let res = await getSimList(this.searchForm);
        this.tableData = this.getFreezeResponse(res, {
          path: "data.array"
        });
        this.total = this.getFreezeResponse(res, {
          path: "data.totalRecords"
        });
        // 绑定的设备编号做特殊处理
        this.tableData.map(item => {
          if (item.equipCode === 0) {
            item.equipCode = "";
          }
        });
        this.selColumnId = [];
        this.elementTableDoLayout();
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    async initData() {
      try {
        //获取枚举数据
        let promiseList = [
          findDownList(["cardStatus", "isPrivate", "haveToStop", "haveType"]),
          getPackageEnum(),
          getPropEnum(),
          getTypeEnum(),
          getEquipThirdEnum()
        ];
        let [
          findDownListRes,
          packageEnumRes,
          propEnumRes,
          typeEnumRes,
          equipThirdEnumRes
        ] = await Promise.all(promiseList);
        this.cardStatusEnum = this.getFreezeResponse(findDownListRes, {
          path: "data.cardStatus"
        });
        this.isPrivateEnum = this.getFreezeResponse(findDownListRes, {
          path: "data.isPrivate"
        });
        this.haveToStopEnum = this.getFreezeResponse(findDownListRes, {
          path: "data.haveToStop"
        });
        this.ownerTypeEnum = this.getFreezeResponse(findDownListRes, {
          path: "data.haveType"
        });
        this.simPackageEnum = this.getFreezeResponse(packageEnumRes, {
          path: "data"
        });
        this.cardAttributeEnum = this.getFreezeResponse(propEnumRes, {
          path: "data"
        });
        this.cardTypeEnum = this.getFreezeResponse(typeEnumRes, {
          path: "data"
        });
        this.equipThirdEnum = this.getFreezeResponse(equipThirdEnumRes, {
          path: "data"
        });
      } catch (error) {
        printError(error);
      }
    },
    // 表格勾选
    handleSelectionChange(columns) {
      if (columns.length > 100) {
        columns.length = 100;
      }
      this.selColumnId = columns.map(item => {
        return item.id;
      });
      this.selColumnId.length;
    },
    handleSelectAll(columns) {
      if (columns.length > 100) {
        columns.length = 100;
        this.$message.warning("最大多选中数据为100条，已为您默认选中前100条。");
      }
    },
    //只能选中两个数据对比
    checkSelectable(row) {
      return (
        this.selColumnId.length < 100 ||
        this.selColumnId.findIndex(item => {
          return item == row.id;
        }) > -1
      );
    },
    //点击激活/开通/报停
    changeSim(type) {
      if (!this.selColumnId.length) {
        this.$message.warning("请选择数据");
        return;
      }
      let confirmTitleKey = type == 2 ? "开通" : "报停";
      this.$confirm(
        `是否确认${confirmTitleKey}所选SIM卡?`,
        `${confirmTitleKey}SIM卡`,
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }
      )
        .then(() => {
          this.handleSimReq(type, confirmTitleKey);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: `已取消${confirmTitleKey}`
          });
        });
    },
    //激活/开通/报停请求
    async handleSimReq(type) {
      this.loading = true;
      this.handleType = type;
      let resList = await this.concurrentRequest(this.selColumnId);
      this.loading = false;
      let failTotal = 0;
      let successTotal = 0;
      resList.map(res => {
        if (res.code === "OK") {
          failTotal += res.data.fail;
          successTotal += res.data.succ;
        } else {
          failTotal += this.concurrentRequestOption.maxDataSize;
        }
      });
      this.$message({
        type: "info",
        message: `批量修改卡信息操作，${successTotal}条数据成功，${failTotal}条数据失败。`,
        duration: 5000
      });
    },
    // 分批请求之前， 修改接口入参
    concurrentRequestBeforeReq(handleParamData) {
      return {
        type: this.handleType,
        simNo: handleParamData
      };
    },
    // 获取页面操作权限
    getOperateList() {
      // 页面按钮权限
      let operates = [
        {
          label: "详情",
          id: 1
        }
      ];
      return operates;
      // return this.filterOperateBtn(operates);
    },
    async exportSimData() {
      try {
        this.loading = true;
        let exportRes = await simExport(this.searchForm);
        if (exportRes.code == "OK") {
          let url = this.getFreezeResponse(exportRes, {
            path: "data.fileUrl",
            defaultVal: ""
          });
          if (url) exportExcelByUrl(url);
          else exportMessageHandle(this);
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    // 操作--更多
    handleOperate(val, row) {
      if (val.id == 1 && row) {
        this.routerPush({
          name: "simManage/simDetail",
          params: {
            refresh: true,
            id: row.id
          }
        });
      }
    },
    // 导入
    simImport(type) {
      const moduleKey = ["sim_import", "sim_select", "simE6_import"];
      const routeName = [
        "uploadCenter",
        "simManage/imoprtAndHandle",
        "uploadCenter"
      ];
      let route = {
        name: routeName[type],
        params: {
          refresh: true,
          moduleKey: moduleKey[type],
          origin: this.$route.name,
          serverName: E3_BL_STE,
          isNeedObjectParam: true
        }
      };
      this.routerPush(route);
    },
    /*******修改卡信息**********/
    //点击修改卡信息显示弹框
    handleEdit() {
      if (!this.selColumnId.length) {
        this.$message.warning("请选择数据");
        return;
      }
      this.editVisible = true;
    },
    //关闭弹框
    handleClose() {
      this.editVisible = false;
    }
  }
};
</script>
<style lang="scss" scoped>
// .main-wrapper > .container-wrapper .content-view-wrapper {
//   height: calc(100% - 78px);
// }

/deep/.edit-dialog {
  .el-dialog__body {
    padding: 20px;
  }
}
</style>
