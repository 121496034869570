<!--
 * @Description: 修改sim卡信息弹框
 * @Author: ChenXueLin
 * @Date: 2021-11-04 09:37:50
 * @LastEditTime: 2022-07-01 09:26:20
 * @LastEditors: ChenXueLin
-->
<template>
  <!-- 修改卡信息 start -->
  <el-dialog
    v-dialogDrag
    title="修改卡信息"
    :visible.sync="editVisible"
    @open="openDialog"
    width="500px"
    :before-close="closeDialog"
    :close-on-click-modal="false"
    :element-editDialogLoading-background="loadingBackground"
    custom-class="edit-dialog"
    v-loading="editDialogLoading"
  >
    <el-form
      ref="editSimForm"
      label-width="100px"
      :model="editSimForm"
      :rules="editSimFormRules"
    >
      <el-form-item label="卡套餐" prop="simPackageId">
        <e6-vr-select
          v-model="editSimForm.simPackageId"
          :data="simPackageEnum"
          placeholder="SIM卡套餐"
          title="SIM卡套餐"
          :props="{
            id: 'simPackageId',
            label: 'simPackageName'
          }"
          clearable
        ></e6-vr-select>
      </el-form-item>
      <el-form-item label="卡属性" prop="simPropId">
        <e6-vr-select
          v-model="editSimForm.simPropId"
          :data="cardAttributeEnum"
          placeholder="卡属性"
          title="卡属性"
          :props="{
            id: 'simPropId',
            label: 'simPropName'
          }"
          clearable
        ></e6-vr-select>
      </el-form-item>
      <el-form-item label="卡类型" prop="simTypeId">
        <e6-vr-select
          v-model="editSimForm.simTypeId"
          :data="cardTypeEnum"
          placeholder="卡类型"
          title="卡类型"
          :props="{
            id: 'simTypeId',
            label: 'simTypeName'
          }"
          clearable
        ></e6-vr-select>
      </el-form-item>
      <el-form-item label="是否需要报停" prop="needStop">
        <e6-vr-select
          v-model="editSimForm.needStop"
          :data="haveToStopEnum"
          placeholder="是否需要报停"
          title="是否需要报停"
          :props="{
            id: 'codeValue',
            label: 'codeName'
          }"
          clearable
        ></e6-vr-select>
      </el-form-item>
    </el-form>
    <div class="dialog-footer" slot="footer">
      <el-button class="cancel" @click="closeDialog">取消</el-button>
      <el-button type="primary" @click="handleSubmit">确定</el-button>
    </div>
  </el-dialog>
  <!-- 修改卡信息 end -->
</template>

<script>
import base from "@/mixins/base";
import { batchupdateSim, getSimNeedUpdateData } from "@/api";
import { printError } from "@/utils/util";
import concurrentRequest from "@/mixins/concurrentRequest";
export default {
  name: "",
  mixins: [base, concurrentRequest],
  components: {},
  data() {
    return {
      editDialogLoading: false,
      concurrentRequestOption: {
        requestsAPI: batchupdateSim
      },
      editSimForm: {
        simPackageId: "", //卡套餐
        simPropId: "", //卡属性
        simTypeId: "", //卡类型
        needStop: "" //是否需要报停
      },
      editSimFormRules: {
        simPackageId: [
          {
            required: true,
            message: "请选择卡套餐",
            trigger: ["blur", "change"]
          }
        ],
        simPropId: [
          {
            required: true,
            message: "请选择卡属性",
            trigger: ["blur", "change"]
          }
        ],
        simTypeId: [
          {
            required: true,
            message: "请选择卡类型",
            trigger: ["blur", "change"]
          }
        ],
        needStop: [
          {
            required: true,
            message: "请选择是否需要报停",
            trigger: ["blur", "change"]
          }
        ]
      }
    };
  },
  props: {
    editVisible: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: "1"
    },
    selColumnId: {
      type: Array,
      default: () => []
    },
    simPackageEnum: {
      type: Array,
      default: () => []
    },
    cardAttributeEnum: {
      type: Array,
      default: () => []
    },
    cardTypeEnum: {
      type: Array,
      default: () => []
    },
    haveToStopEnum: {
      type: Array,
      default: () => []
    }
  },
  computed: {},
  watch: {},
  methods: {
    //点击确定添加
    handleSubmit() {
      this.$refs.editSimForm.validate(valid => {
        if (valid) {
          this.batchupdateSim();
        }
      });
    },
    async getSimNeedUpdateData() {
      try {
        this.editDialogLoading = true;
        let res = await getSimNeedUpdateData({ id: this.selColumnId[0] });
        if (res.code == "OK") {
          this.editSimForm = {
            simPackageId: res.data.simPackageId,
            simPropId: res.data.simPropId,
            simTypeId: res.data.simTypeId,
            needStop: res.data.needStop + ""
          };
        }
      } catch (error) {
        printError(error);
      } finally {
        this.editDialogLoading = false;
      }
    },
    // 分批请求之前， 修改接口入参
    concurrentRequestBeforeReq(handleParamData) {
      return {
        ...this.editSimForm,
        simNo: handleParamData
      };
    },
    //批量编辑请求
    async batchupdateSim() {
      this.editDialogLoading = true;
      let resList = await this.concurrentRequest(this.selColumnId);
      this.editDialogLoading = false;
      let failTotal = 0;
      let successTotal = 0;
      resList.map(res => {
        if (res.code === "OK") {
          failTotal += res.data.fail;
          successTotal += res.data.succ;
        }
      });
      this.$message({
        type: "info",
        message: `批量修改卡信息操作，${successTotal}条数据成功，${failTotal}条数据失败。`,
        duration: 5000
      });
      this.closeDialog();
      this.$emit("afterSaveSuccess");
    },
    //打开
    openDialog() {
      this.$nextTick(() => {
        this.$refs.editSimForm.clearValidate();
        if (this.selColumnId.length === 1) this.getSimNeedUpdateData();
      });
    },
    //关闭
    closeDialog() {
      this.editSimForm = {
        simPackageId: "", //卡套餐
        simPropId: "", //卡属性
        simTypeId: "", //卡类型
        needStop: "" //是否需要报停
      };
      this.$emit("handleClose");
    }
  }
};
</script>
<style lang="scss" scoped></style>
